export const initValues = {
  volunteer: {
    firstName: '',
    lastName: '',
    email: '',
    gsm: '',
    ssin: '',
    kitchen: '',
    account: '',
    street: '',
    municipality: '',
    extra_information: '',
    siteLang: ''
  }
}

export const initErrors = {
  volunteer: {}
}

export const initComponentErrors = {
  volunteer: {}
}