export const initValues = {
  friend: {
    email: '',
    code: '',
    language: ''
  }
}

export const initErrors = {
  friend: {
    email: '',
  }
}

export const initComponentErrors = {
  friend: {
    email: ''
  }
}